<template>
  <div id="exhibition_list">
    <!-- loader -->
    <div v-if="photos.length === 0" class="global-loading">
      <div v-html="translate.global.loading_exhibition_data[language] + ' &hellip;'"></div>
    </div>
    <!-- header -->
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]" />
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <!-- list -->
        <div class="col-lg-8">
          <h2 v-if="currentPerson" class="list_title" v-html="translate.global.exponate_ausstellung_zur_person[language] + ': '
            + currentPerson['dcterms:title'][0]['@value']"></h2>
          <ExhibitionList v-if="hasPersonPhotoInExhibition(photoId)" v-for="(photoId, i) in photosInExhibition" :photoId="photoId" :key="'photolist_item_inex_' + i" />
          <h2 v-if="currentPerson" class="list_title" v-html="translate.global.exponate_bestand_zur_person[language] + ': '
            + currentPerson['dcterms:title'][0]['@value']"></h2>
          <ExhibitionList v-if="hasPersonPhotoInStore(photo)" v-for="(photo, i) in photos" :photoId="photo['o:id']" :key="'photolist_item_instore_' + i" />
        </div>
        <!-- side -->
        <div class="col-lg-4 exhibition_side_col">
          <img v-if="currentMedia && typeof currentMedia['o:thumbnail_urls'] !== 'undefined'" class="img-fluid mb-3"
            :src="currentMedia['o:thumbnail_urls']['large']"
            :alt="currentPerson['dcterms:title']">

          <h1 v-if="currentPerson" v-html="currentPerson['dcterms:title'][0]['@value']"></h1>

          <dl>
            <template v-if="currentPerson && typeof currentPerson['foaf:familyName'] !== 'undefined'">
              <dt v-html="translate.global.Nachname[language]"></dt>
              <dd v-html="currentPerson['foaf:familyName'][0]['@value']"></dd>
            </template>
            <template v-if="currentPerson && typeof currentPerson['foaf:givenName'] !== 'undefined'">
              <dt v-html="translate.global.Vorname[language]"></dt>
              <dd v-html="currentPerson['foaf:givenName'][0]['@value']"></dd>
            </template>
            <template v-if="currentPerson && typeof currentPerson['ghwk:dateofbirth'] !== 'undefined'">
              <dt v-html="translate.global.Geburtsdatum[language]"></dt>
              <dd v-html="currentPerson['ghwk:dateofbirth'][0]['@value']"></dd>
            </template>
            <template v-if="currentPerson && typeof currentPerson['ghwk:dateofdeath'] !== 'undefined'">
              <dt v-html="translate.global.Todesdatum[language]"></dt>
              <dd v-html="currentPerson['ghwk:dateofdeath'][0]['@value']"></dd>
            </template>
          </dl>

          <p v-if="currentPerson && typeof currentPerson['ghwk:wdperson'] !== 'undefined'">
            <a :href="currentPerson['ghwk:wdperson'][0]['@id']"
              v-html="currentPerson['dcterms:title'][0]['@value'] + ' ' + translate['global']['bei'][language] + ' Wikidata'"
              target="_blank"></a>
          </p>

          <hr>
          <dl>
            <dt v-html="translate.metadata_label['thematische Kategorie'][language]"></dt>
            <dd><SubjectcategoryNavigation /></dd>
            <dt v-html="translate.metadata_label['Personen'][language]"></dt>
            <dd><PersonsNavigation /></dd>
            <dt v-html="translate.metadata_label['Orte'][language]"></dt>
            <dd><LocationsNavigation /></dd>
          </dl>
          <hr>
          <GolbalListLinks />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import ExhibitionList from '@/components/partials/ExhibitionList.vue'
import SubjectcategoryNavigation from '@/components/partials/SubjectcategoryNavigation.vue'
import PersonsNavigation from '@/components/partials/PersonsNavigation.vue'
import LocationsNavigation from '@/components/partials/LocationsNavigation.vue'
import GolbalListLinks from '@/components/partials/GolbalListLinks.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'Person',
  components: {
    'site-header': header,
    ExhibitionList,
    SubjectcategoryNavigation,
    PersonsNavigation,
    GolbalListLinks,
    LocationsNavigation
  },
  data () {
    return {
      currentPerson: null,
      currentMedia: null
    }
  },
  watch: {
    persons: function () {
      if (this.persons.length > 0) {
        if (this.$route.params.id) {
          this.setCurrentPerson(this.$route.params.id)
        }
      }
    },
    media: function () {
      if (this.media.length > 0 && this.currentPerson !== null) {
        this.setCurrentMedia()
      }
    },
    '$route' (to) {
      this.setCurrentPerson(to.params.id)
    },
  },
  methods: {
    setCurrentPerson(id) {
      if (this.persons && this.persons.length > 0) {
        this.currentPerson = this.persons.find(prop => prop['o:id'] === Number(id))
      }
      this.setCurrentMedia()
    },
    setCurrentMedia() {
      if (this.currentPerson && this.currentPerson['o:media'] && this.media) {
        this.currentMedia = this.media.find(prop =>
          this.currentPerson !== null &&
          typeof this.currentPerson !== 'undefined' &&
          prop['o:id'] === this.currentPerson['o:media'][0]['o:id'])
      }
    },
    hasPersonPhotoInExhibition(id) {
      let photo = this.photos.find(prop =>
        prop['o:id'] === id &&
        typeof prop['ghwk:depictedperson'] !== 'undefined' &&
        this.currentPerson !== null &&
        typeof this.currentPerson !== 'undefined' &&
        typeof prop['ghwk:depictedperson'].find(
          person => person['value_resource_id'] === this.currentPerson['o:id']) !== 'undefined'
      )
      return (typeof photo !== 'undefined')? true : false
    },
    hasPersonPhotoInStore(photo) {
      if (photo['ghwk:exhibitstatus'][0]['@value'] !== 'im Bestand') {
        return false
      }
      if (photo && typeof photo['ghwk:depictedperson'] !== 'undefined') {
        let result = photo['ghwk:depictedperson'].find(prop =>
          this.currentPerson !== null &&
          typeof this.currentPerson !== 'undefined' &&
          prop['value_resource_id'] === this.currentPerson['o:id']
        )
        return (typeof result !== 'undefined')? true : false
      }
      return false
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      photos: 'items/photos',
      photosInExhibition: 'items/photosInExhibition',
      media: 'media/media',
      persons: 'persons/persons'
    })
  },
  created () {
    if (this.$route.params.id) {
      this.setCurrentPerson(this.$route.params.id)
    }
  }
}
</script>